// import { useCallback, useEffect, useState } from 'react';
import { Button, FontIcon } from 'react-md';

import config from '../../../../config';
import objectToQueryString from '../../../../util/objectToQueryString';

/** @typedef {import('./zExtensionRequestParams').ExtensionRequestParams} ExtensionRequestParams*/

/**
 * @param {{className?:string, values: ExtensionRequestParams}} props
 */
const BoardsURLInput = ({ className = '', values }) => {
  const query = objectToQueryString(values);
  const url = new URL(`${config.services.api}/bext?${query}`);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={url.href} target="_blank" className={className}>
      <Button raised primary iconEl={<FontIcon>{'download'}</FontIcon>}>
        Download
      </Button>
    </a>
  );
};

export default BoardsURLInput;
