/**
 *
 * @param {Record<string, unknown>} obj
 * @returns {string}
 */
export default function objectToQueryString(obj) {
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      // Flatten nested objects (e.g., search.scopeButtonIndex)
      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        params.append(`${key}.${nestedKey}`, nestedValue);
      });
    } else if (value !== undefined) {
      params.append(key, `${value}`);
    }
  });

  return params.toString();
}
