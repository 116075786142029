import { z } from 'zod';

export const zOptionalString = z.string().optional();

/** @typedef {z.infer<typeof zExtensionRequestParams>} ExtensionRequestParams */

export const zExtensionRequestParams = z.object({
  boardsURL: z.string(),
  verseURL: zOptionalString,
  authPath: zOptionalString,
  search: z.object({
    scopeButtonIndex: z.number().int(),
    scopeButtonRemove: z.number().int().optional(),
  }),
});
