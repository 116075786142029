import { useCallback, useEffect, useState } from 'react';
import { SelectField } from 'react-md';

/** @typedef {import('../../OrgAPI').Org} Org */
/** @typedef {Partial<import('./zExtensionRequestParams').ExtensionRequestParams>} ExtensionRequestParams */

/**
 * @param {{ orgs: Org[],  onChange: (values: ExtensionRequestParams ) => void }} props
 */
export default function FormPrefill({ orgs, onChange }) {
  const [selectedOrg, setSelectedOrg] = useState(
    /** @type {Org|undefined} */ (undefined)
  );
  const [selectedClient, setSelectedClient] = useState(
    /** @type { import('../../OrgAPI').OrgEnvClient | undefined } */ (undefined)
  );

  useEffect(() => {
    if (selectedOrg) {
      const newSelectedClient = selectedOrg.env.clients[0];
      const authPath =
        selectedOrg.env.appURI.endsWith('huddo.com') && newSelectedClient
          ? `/auth/connections/${newSelectedClient?.externalId}`
          : undefined;

      const addHttps = !selectedOrg.env.appURI.startsWith('https://');
      setSelectedClient(newSelectedClient);
      onChange({
        boardsURL: addHttps
          ? `https://${selectedOrg.env.appURI}`
          : selectedOrg.env.appURI,
        authPath,
        verseURL: undefined,
      });
    }
  }, [onChange, selectedOrg]);

  const urls = selectedOrg?.env.clients.map((client) => client.url) || [];

  const onSelectClient = useCallback(
    (/** @type {string|number} */ value) => {
      const client = selectedOrg?.env.clients.find(
        (client) => client.url === value
      );
      setSelectedClient(client);
    },
    [selectedOrg?.env.clients]
  );

  const orgSelectItems = orgs?.map((org) => ({
    label: org.name,
    value: org,
  }));

  return (
    <>
      <SelectField
        id="select-org"
        label="Organisation"
        menuItems={orgSelectItems}
        fullWidth
        // @ts-ignore
        onChange={setSelectedOrg}
      />

      <SelectField
        id="select-conn-host"
        label="Connections Host"
        disabled={!selectedOrg}
        fullWidth
        defaultValue={urls[0]}
        menuItems={urls}
        simplifiedMenu={true}
        value={selectedClient?.url}
        onChange={onSelectClient}
      />
    </>
  );
}
