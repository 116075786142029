// import { useCallback, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Button, FontIcon } from 'react-md';

import createLink from './createLink';

/** @typedef {import('./zExtensionRequestParams').ExtensionRequestParams} ExtensionRequestParams*/

/**
 * @param {{className?:string, values: ExtensionRequestParams, onLinkCreated: (linkId:string)=>void}} props
 */
const ShellButton = ({ values, onLinkCreated }) => {
  const onClick = useCallback(async () => {
    const linkId = await createLink(values);
    onLinkCreated(linkId);
  }, [values, onLinkCreated]);

  return (
    <Button
      raised
      primary
      iconEl={<FontIcon>{'terminal'}</FontIcon>}
      onClick={onClick}
    >
      shell command
    </Button>
  );
};

export default ShellButton;
