import copy from 'copy-to-clipboard';
import { useCallback, useEffect, useState } from 'react';
import { Card, CardActions, CardText, CardTitle, TextField } from 'react-md';

import config from '../../../../config';
import { useOrgs } from '../../useOrgs';
import DownloadButton from './DownloadButton';
import FormInputs from './FormInputs';
import FormPrefill from './FormPrefill';
import ShellButton from './ShellButton';
import { zExtensionRequestParams } from './zExtensionRequestParams';

const boardsURLDefault = 'https://';
const verseURLDefault = 'https://';
const authPathDefault = '/auth/connections';

const Form = () => {
  const [formState, setFormState] = useState(
    zExtensionRequestParams.parse({
      boardsURL: boardsURLDefault,
      verseURL: verseURLDefault,
      authPath: authPathDefault,
      search: {
        scopeButtonIndex: 7,
        scopeButtonRemove: undefined,
      },
    })
  );
  console.debug('formState:', formState);

  const [linkId, setLinkId] = useState(
    /** @type {string|undefined} */ (undefined)
  );
  const [copySuccess, setCopySuccess] = useState(false);

  const orgs = useOrgs();

  useEffect(() => {
    setLinkId(undefined);
    setCopySuccess(false);
  }, [formState.boardsURL, formState.verseURL]);

  return (
    <form className="bext-form">
      <Card>
        <CardTitle style={{ paddingBottom: 0 }} title="Prefill" />
        <CardText style={{ paddingTop: 0 }}>
          <FormPrefill
            orgs={orgs || []}
            onChange={useCallback((prefill) => {
              setFormState((state) => ({ ...state, ...prefill }));
            }, [])}
          />
        </CardText>
      </Card>
      <br />
      <Card>
        <CardText>
          <FormInputs values={formState} onChange={setFormState} />
        </CardText>

        <CardActions>
          <div className="form-buttons">
            <DownloadButton values={formState} />
            <ShellButton
              values={formState}
              onLinkCreated={useCallback((linkId) => {
                setLinkId(linkId);
              }, [])}
            />
          </div>
        </CardActions>
        {linkId && (
          <CardActions>
            <TextField
              rows={2}
              value={`curl ${config.services.api}/bext/link/${linkId} | tar -xz`}
              onClick={(event) => {
                event.target.select();
                setCopySuccess(copy(event.target.value));
              }}
            />
          </CardActions>
        )}
        {copySuccess && (
          <CardActions>
            <div className="copy-success">Copied!</div>
          </CardActions>
        )}
      </Card>
    </form>
  );
};
export default Form;
