import { useCallback } from 'react';
import { TextField } from 'react-md';
import { z } from 'zod';

import BoardsURLInput from './BoardsURLInput';
import VerseURLInput from './VerseURLInput';

/** @typedef {import('./zExtensionRequestParams').ExtensionRequestParams} ExtensionRequestParams */

const zCoerceInt = z.coerce.number().int();

/**
 * @param {{ values:ExtensionRequestParams , onChange: (values: ExtensionRequestParams) => void }} props
 */
export default function FormInputs({ values, onChange }) {
  const { boardsURL, authPath, verseURL, search } = values;

  return (
    <>
      <BoardsURLInput
        value={boardsURL}
        onChange={useCallback(
          (/** @type {string} */ boardsURL) => {
            onChange({ ...values, boardsURL });
          },
          [onChange, values]
        )}
      />

      <TextField
        label="Boards Auth Path"
        id={'boards-url'}
        value={authPath}
        disabled={!boardsURL?.endsWith('huddo.com')}
        onChange={useCallback(
          (/** @type {string|number} */ value) => {
            const authPath = value ? `${value}` : '';
            onChange({ ...values, authPath });
          },
          [onChange, values]
        )}
      />
      <VerseURLInput
        className="bext-verse"
        value={verseURL}
        onChange={useCallback(
          (verseURL) => {
            onChange({ ...values, verseURL });
          },
          [onChange, values]
        )}
      />
      <details>
        <summary>
          <h4>Connections 8 Search</h4>
        </summary>
        <TextField
          label="Scope Button Index"
          id={'scope-button-index'}
          helpText={"Add 'Boards' at this index in the list of search scopes."}
          type="number"
          value={search.scopeButtonIndex}
          onChange={useCallback(
            (/** @type {unknown} */ value) => {
              onChange({
                ...values,
                search: {
                  ...search,
                  scopeButtonIndex: zCoerceInt.parse(value),
                },
              });
            },
            [onChange, search, values]
          )}
        />
        <TextField
          label="Scope Button Remove Index"
          helpText={
            'Remove the button at this index in the list of search scopes.'
          }
          id={'scope-button-remove'}
          type="number"
          value={search.scopeButtonRemove}
          onChange={useCallback(
            (/** @type {unknown} */ value) => {
              onChange({
                ...values,
                search: {
                  ...search,
                  scopeButtonRemove:
                    `${value}`.length === 0
                      ? undefined
                      : zCoerceInt.optional().parse(value),
                },
              });
            },
            [onChange, search, values]
          )}
        />
      </details>
    </>
  );
}
