import config from '../../../../config';

/** @typedef {import('./zExtensionRequestParams').ExtensionRequestParams} ExtensionRequestParams*/

/**
 * @param {ExtensionRequestParams} values
 */
export default async function createLink(values) {
  const { boardsURL, verseURL, authPath, search } = values;
  const url = `${config.services.api}/bext/link`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      boardsURL,
      verseURL,
      authPath,
      search,
    }),
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });

  // eslint-disable-next-line no-return-await
  return await response.text();
}
